.pin-button {
  width: 123px;
  height: 123px;
}

.refresh-button {
  width: 123px;
  height: 70px;
}

.top-button {
  width: 100px;
}

.top-pin-row {
  display: flex;
  justify-content: space-between;
}

.recently-column {
  width: 300px;
}

.recently-column ol {
  padding-left: 16px;
}