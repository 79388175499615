$spaceMinWidth: 33px;
$spaceWidth: 200px;

.app-content {
    display: flex;
    justify-content: center;
    min-width: 1000px;
    margin: 50px 0px;
}

.app-content-side-space{
    width:$spaceWidth;
    min-width: $spaceMinWidth;
    flex-shrink: 0.5;
}

.app-content-middle{
    flex-grow: 1;
    min-width: 1000px - $spaceMinWidth * 2;
}