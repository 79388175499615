.top-button {
  width: 100px;
}

/* --- top button list, begin --- */
#top-button-container {
  display: flex;
  justify-content: space-between;
}
/* --- top button list, end --- */

/* --- two column, begin --- */
.two-parent {
  overflow: hidden;
  bottom: 0px;
}

.two-right {
  overflow: auto;
  float: right;
  width: 45%;
  height: 100%;
}

.two-left {
  overflow: auto;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
}

.two-right textarea {
  overflow: hidden;
}

// 滚动条
.two-child-column::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.two-child-column::-webkit-scrollbar-thumb{
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: rgb(195, 195, 195);
  // background-image: -webkit-linear-gradient(
  //     45deg,
  //     rgba(105, 105, 105, 0.2) 21%,
  //     transparent 21% 41%,
  //     rgba(105, 105, 105, 0.2) 41% 59%,
  //     transparent 59% 79%,
  //     rgba(105, 105, 105, 0.2) 79% 100%,
  // );
}
.two-child-column::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ededed;
  border-radius: 10px;
}

/* --- two column, end --- */


/* --- preview, begin --- */

.markdown-preview {
  width: 900px;
}
.markdown-preview.column-two {
  width: auto;
}

/* --- preview, end --- */